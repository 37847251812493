import functions, { formatMoney } from "./functions.js";
import tplLoader from "./tpl-loader.js";
import pagination from "./pagination.js";
import Modal from 'bootstrap/js/dist/modal';
import Slider from "./Slider";
import lozad from 'lozad';

const list = {
    allowInfoWindow: true,

    $form: null,
    form: null,
    $mobileForm: null,
    mobileForm: null,
    $filtersForm: null,
    filtersForm: null,
    areaOnMap: null,
    $shownItems: null,
    $total: null,
    $body: {},
    $list: null,
    list: null,
    $listWrap: null,
    $map: null,
    map: null,
    infoWindow: null,
    cluster: null,
    lastEvent: null,
    // form params
    params: {},
    defaults: {
        dateFrom: '',
        dateTo: '',
        destination: '',
        objectTypeId: '',
        guests: '0,0,0',
        dates: '',
        numberOfPets: '',
        personsInObject: '',
        sortBy: 0,
        _page: 1,
        perPage: 10,
        rooms: '',
        bathrooms: '',
        ms: '',
        themeId: '',
        tagCategoryId: '',
        distanceBeach: '',
        distanceCenter: '',
        objectId: '',
        parking: '',
        pool: '',
        pets: '',
        grill: '',
        ac: '',
        sattv: '',
        internet: '',
        disabledPersons: '',
        washingMachine: '',
        heatedPool: '',
        coordinates: '',
        language: functions.getLang()
    },
    mainFields: ['dateFrom', 'dateTo', 'destination', 'objectTypeId', 'guests', 'personsInObject', 'sortBy', '_page', 'dates', 'ms'],
    // set by hash change to prevent change event on form fields
    pauseChange: false,
    // hashchange and form fields change are delayed
    changeTimeout: null,
    loading: false,
    // ajax load id
    loadId: 0,
    total: null,
    // for non ajax form to reset page on normal submit
    paginated: false,
    disabledFields: {},
    locations: null,

    setLoadingMode() {
        if ( !list.loading ) {
            list.loading = true;
            if (list.$body.length) {
                list.$body.addClass('loading');
                list.$listWrap.prepend(tplLoader).scrollTop(0);
                list.$listWrap.scrollTop(0); // mobile
                $('.list').scrollTop(0); 
                list.$list.scrollTop(0);
                list.infoWindow && list.infoWindow.close();
               
            } else {
                list.$form
                    .find('.form-submit').prop('disabled', true)
                    .find('i').addClass('fa-spinner fa-spin fa-lg');
            }
        }
    },
    splitDates(params) {
        if(params.dates)
        {
            let val = params.dates.split(' - ');
            params.dateFrom  = val[0];
            params.dateTo    = val[1];
            params.dates = null;
        }
        else
        {
            return;
        }
        
    },
    unsetLoadingMode() {
        if ( list.loading ) {
            list.loading = false;
            list.$body.removeClass('loading').find('.list-loader').remove();
        }
    },
    toggleFilters(toggle) {
        list.moreFilters = toggle === undefined ? !list.moreFilters : toggle;
        // list.$moreFiltersBtns.toggleClass('active', list.moreFilters);
        // list.$moreFiltersBtnsIcons.toggleClass('fa-chevron-up', list.moreFilters);
    },
    toggleMobileForm(toggle) {
        if ( toggle ) {
            list.$body.addClass('modal-active');
        } else {
            list.$body.removeClass('modal-active');
            list.toggleFilters(false);
        }
    },
    getParams() {
        let queryParams = functions.getUrlData();
        list.params = $.extend({}, list.defaults, functions.getUrlData(true));
        if (queryParams._page) list.params._page = queryParams._page
        list.setFiltersCount();
    },
    setDisabledFields() {
        let disabledFields = list.$form.attr('data-disabledFields');
        if ( disabledFields ) {
            disabledFields = JSON.parse(disabledFields);
            $.map(disabledFields, (value, name) => {
                if ( list.form[name] ) {
                    list.disabledFields[name] = true;
                    list.defaults[name] = value;
                    list.form[name].disabled = true;
                    if ( name == 'dateFrom' || name == 'dateTo' ) {
                        $(list.form[name]).next().prop('disabled', true);
                    }
                }
            });
        }
    },
    onChange(e) {
        clearTimeout(list.changeTimeout);
        
        if ( list.pauseChange ) {
            return;
        }
        // if changed field is date range and not have dateFrom & dateTo prevent url push
        if ( e && e.target.name == 'dates' ) {
            let val = e.target.value.split(' - ');
            
            if (val.length == 2) $('.dates-clear').removeClass('d-none');

            if ( val.length != 2 && val[0] != '') {
                return;
            }  
            
        }

        if ( e && e.target.name == 'destination') {
            
            if(list.params.destination == '' && list.params.coordinates != '') {
                list.params.coordinates = ''; 
            }
        }
        if( e && list.mainFields.indexOf(e.target.name) === -1)
        {
            return;
        }
        // not advanced (ajax) search
        if ( !list.$body.length ) {
            return;
        }

        list.allowInfoWindow = false;

        list.changeTimeout = setTimeout(() => {
            functions.setUrlData(list.getFormData(), list.$body.length);
        }, 300);
    },
    getFormData() {
        let formData = $.extend(
            functions.isMobile() ? functions.getFormData(list.$mobileForm) : functions.getFormData(list.$form), 
            functions.getFormData(list.$filtersForm), {
                coordinates: list.params.coordinates,
                _page: list.paginated ? list.params._page : 1,
                sortBy: list.paginated ? list.params.sortBy : 0
                }
            );
            
        list.paginated = false;
        // do not send same values as defaults
        $.map(formData, (val, key) => {
            if ( list.defaults[key] == val ) {
                delete formData[key];
            }
        });

        if (formData.destination && formData.coordinates) delete formData.coordinates;
        return formData;
    },
    setFormData()
    {
        if (list.params.dateFrom && list.params.dateTo) list.params.dates = list.params.dateFrom + ' - ' + list.params.dateTo

        functions.setFormData(list.form, list.params);
        if (list.$mobileForm && list.$mobileForm.length) functions.setFormData(list.mobileForm, list.params);
        if ( list.$filtersForm && list.$filtersForm.length ) functions.setFormData(list.filtersForm, list.params);

    },
    init() {
        list.$form = $('.search-form');
        if( !list.$form.length )
        {
            return;
        }

        list.$filtersForm = $('.filters-form');
        if ( list.$filtersForm && list.$filtersForm.length ) list.filtersForm = list.$filtersForm[0]

        list.$mobileForm = $('.main-fields-mobile-form');
        if ( list.$mobileForm && list.$mobileForm.length ) list.mobileForm = list.$mobileForm[0]

        list.$body = $('.body-listing');
        list.form = list.$form[0]

        list.areaOnMap = $(list.form).attr('data-areaonmap') || 'Area on map';

        // list.$moreFiltersBtns = list.$form.find('.more-filters');
        // list.$moreFiltersBtnsIcons = list.$moreFiltersBtns.find('i');
        // list.$filtersCount = list.$moreFiltersBtns.find('.filters-count');
        // list.$moreFiltersContainer = list.$form.find('.more-filters-container');

        // set default params
        list.defaultParams = list.$form.attr('data-params') ? JSON.parse(list.$form.attr('data-params')) : [];
        $.map(list.defaultParams, (value, key) => {
            list.params[key] = value;
        });

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            list.params.ms = '';
            $(list.form.ms).val('').trigger('change');
        });

        list.setDisabledFields();
        list.getParams();

        $.map(list.defaultParams, (value, key) => {
            if(! list.params[key]) list.params[key] = value;
        });
        
        if (list.params.dateFrom && list.params.dateTo) list.params.dates = list.params.dateFrom + ' - ' + list.params.dateTo
        if (list.params.dates) $('.dates-clear').removeClass('d-none');

        // functions.setFormData(list.form, list.params);
        // if (list.filterForm) functions.setFormData(list.filterForm, list.params);

        this.setFormData()
        list.$body.length && list.find();

        // register form fields changes
        list.$form.on('change', list.onChange);

        list.$mobileForm.on('submit', e => {
            e.preventDefault()
            list.onChange()
        })

        list.$filtersForm.on('submit', e => {
            e.preventDefault()
            list.onChange()
        })

        $("input[name='dates']").on('change', function(e) {
            let val = e.target.value.split(' - ');
            if (val.length == 2) $('.dates-clear').removeClass('d-none');
        })


        list.$form.on('submit', e => {
            e.preventDefault();
            if ( list.$body.length ) {
                list.onChange();
                list.toggleMobileForm(false);
                //list.closeModal();
            } else {
                // non ajax form
                let data = $.param(functions.getFormData(list.$form)),
                toHash = list.$form.hasClass('form-hash');
                let href = location.protocol + '//' + location.host + list.$form.attr('action');
                if ( data ) {
                    href += (href.indexOf('?') === -1 ? (toHash ? '#' : '?') : '&');
                }
                href += data;
                list.setLoadingMode();
                list.toggleFilters(false);
                location.href = href;
            }
        });
        // register hashchange which trigger load
        $(window).on('hashchange', () => {
            list.pauseChange = true;
            clearTimeout(list.changeTimeout);
            list.changeTimeout = setTimeout(() => {
                list.getParams();
                this.setFormData()
                list.markers && list.clearMarkers();
                list.find();
                list.params.coordinates && $(list.form.destination).next().find('.select2-selection__placeholder').text(list.areaOnMap);
                list.pauseChange = false;
            }, 100);
        });

        $('.dates-clear').on('click', function(e) {
            $('.dates-clear').addClass('d-none');
            e.stopPropagation();
        })

        // register paginate        
        $(document).on('click', '.pagination a[data-page]', e => {
            e.preventDefault();
            list.paginate(+$(e.currentTarget).attr('data-page'));
        });

        // more filters actions
        // list.$moreFiltersBtns.on('click', list.toggleFilters);
        // list.$moreFiltersContainer.find('.apply-filters').on('click', () => {
        //     list.toggleFilters(false);
        //     if ( list.$body.length ) {
        //         list.onChange();
        //     } else {
        //         list.$form.submit();
        //     }
        // });
        $('.clear-filters').on('click', e => {
            let changed = false;
            let formData = {}
            $.map(list.params, (value, key) => {
                console.log(key, list.mainFields.indexOf(key) === -1);
                if ( list.mainFields.indexOf(key) === -1 ) {
                    if ( list.params[key] && list.params[key] != list.defaults[key] ) {
                        changed = true;
                    }
                    console.log(key);
                    list.params[key] = list.defaults[key];
                }
            });
            list.setFormData();
            if ( changed ) {
                if ( !e.target.classList.contains('clear-filters-mobile') ) {
                    list.onChange();
                }
                if ( !list.$body.length ) {
                    list.$form.submit();
                }
            }
        });

        $('#fullscreen').on('click tap vclick', function() {
            $('.list').addClass('hide-list-list');
            $('.map-and-filters').addClass('d-none');
            $('.map-close-btn').removeClass('d-none');
            $('.list-map').addClass('show-map-list');
            $('.list-wrap').addClass('list-wrap-overflow');
        });
        
        $('#close-map').on('click tap vclick', function() {
            $('.list').removeClass('hide-list-list');
            $('.map-and-filters').removeClass('d-none');
            $('.map-close-btn').addClass('d-none');
            $('.list-map').removeClass('show-map-list');
            $('.list-wrap').removeClass('list-wrap-overflow');
        });

        // show list item on map
        if ( list.$list ) {
            list.$list.on('click', '.view-on-map', e => {
                e.preventDefault();
                const $this = $(e.currentTarget).closest('.card');
                const objectId = +$this.attr('data-publicId');
                const marker = list.markers.filter(marker => marker.objectId === objectId)[0];
                list.suppressMapEvents = true;
                // list.map.setCenter(marker.position);
                // list.map.setZoom(15);              
                if (functions.isMobile())
                {
                    $('.list').addClass('hide-list-list');
                    $('.map-and-filters').addClass('d-none');
                    $('.map-close-btn').removeClass('d-none');
                    $('.list-map').addClass('show-map-list');
                    $('.list-wrap').addClass('list-wrap-overflow');
                }  
                google.maps.event.trigger(marker, 'click');
            });
        }


    },
    
    setFiltersCount() {
        let filtersCount = 0;
        $.map(list.params, (val, name) => {
            list.mainFields.indexOf(name) === -1 && (val !== list.defaults[name] || list.disabledFields[name]) && (filtersCount++);
        });
        // list.$filtersCount.html(filtersCount || '');
    },
    loadMap() {
        if ( $('.list-map').length ) {
            window.loadGoogleMaps().then(list.initMap);
        }
    },
    initMap() {
        list.$map = $('.list-map');
        list.map = new google.maps.Map(list.$map[0], {
            zoom: 10,
            center: { lat: 45.12740978317192, lng: 13.902139984375038 },
            gestureHandling: 'greedy',
            mapTypeControl: false,
            styles: [{
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [
                    { visibility: 'off' }
                ]
            }]
        });
        // only trigger mapfind when zoomout or dragend, zoomin we already have data
        list.map.addListener('zoom_changed', () => {
            if ( list.suppressMapEvents ) {
                return;
            }
            list.markers && list.clearMarkers();
            list.lastEvent = 'zoom';
        });
        list.map.addListener('dragend', () => {
            if ( list.suppressMapEvents ) {
                return;
            }
            list.markers && list.clearMarkers();
            list.lastEvent = 'dragend';
        });
        list.map.addListener('bounds_changed', () => {
            if ( list.suppressMapEvents ) {
                setTimeout(() => {
                    list.suppressMapEvents = false;
                });
            }
        });
        list.map.addListener('idle', () => {
            if ( list.suppressMapEvents ) {
                return;
            }
            if ( !list.lastEvent ) {
                return;
            }

            const bounds = list.map.getBounds();
            const sw = bounds.getSouthWest();
            const nw = bounds.getNorthEast();
            list.params.coordinates = sw.lat() + ',' + nw.lat() + ',' + sw.lng() + ',' + nw.lng();
            list.params._page = 1;
            if(list.params.destination) {
                list.params.destination = '';
            }
            functions.setUrlData(list.params, true);
        });
    
        // init info window
        list.infoWindow = new google.maps.InfoWindow({
            maxWidth: 275
        });
        list.infoWindow.addListener('domready', () => {
            // init infoWindow slider
        });
    },
    mapFind(ajaxId) {
        let params = Object.assign({}, list.params);
        if ( list.lastEvent ) {
            const bounds = list.map.getBounds();
            const sw = bounds.getSouthWest();
            const nw = bounds.getNorthEast();
            params.coordinates = list.params.coordinates = sw.lat() + ',' + nw.lat() + ',' + sw.lng() + ',' + nw.lng();
        } else {
            if ( !params.destination ) {
                //params.destination = '8';
            }
        }
        let sendParams = this.cleanData();
        $.get('/services/map', sendParams).then(response => {
            if ( ajaxId !== list.loadId ) {
                return;
            }
            list.locations = JSON.parse(response);
            list.setMarkers();
        });
    },
    setMarkers() {
        if ( !list.map ) {
            setTimeout(list.setMarkers, 50);
            return;
        }
        // init each marker
        list.markers = list.locations.map(location => {
            location.lat = +location.lat;
            location.lng = +location.lng;
            const marker = new google.maps.Marker({
                position: location,
                map: list.map,
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 10,
                    fillColor: "#fffff",
                    fillOpacity: 0.7,
                    strokeWeight: 0
                },
            });
            marker.objectId = location.objectId;
            marker.addListener('click', () => {
                const params = {
                    objectId: marker.objectId,
                    dateFrom: list.params.dateFrom,
                    dateTo: list.params.dateTo,
                    guests: list.params.guests,
                    language: functions.getLang(),
                    template: 'map'
                };
                if (list.allowInfoWindow) {
                    $.get('/services/objectDetails', params).then(response => {
                        let res = JSON.parse(response);
                        if ( response ) {
                            list.infoWindow.setContent(res.content);
                            list.infoWindow.setPosition({lat: marker.getPosition().lat(), lng: marker.getPosition().lng()});
                            list.infoWindow.open(list.map);
                        }
                    });
                }
            });
            return marker;
        });
        // init cluster
        // if ( !list.cluster ) {
        //     list.cluster = new MarkerClusterer(list.map, [], {
        //         gridSize: 100,
        //         minimumClusterSize: 3,
        //         maxZoom: 14,
        //         zoomOnClick: false,
        //         styles: [{
        //             // url: '/wp-content/uploads/cluster.png',
        //             textSize: 14,
        //             height: 40,
        //             width: 40
        //         }]
        //     });
        //     // force cluster full zoom
        //     list.cluster.addListener('click', function(cluster) {
        //         list.map.setCenter(cluster.center_);
        //         list.map.setZoom(15);
        //     });
        // }

        // list.cluster.addMarkers(list.markers);
        if ( !list.lastEvent ) {
            list.suppressMapEvents = true;
            if ( list.markers.length === 1 ) {
                list.map.setCenter(list.markers[0].position);
                list.map.setZoom(15);
            }
            else if ( list.markers.length ) {
                const bounds = new google.maps.LatLngBounds();
                for (var i = 0; i < list.markers.length; i++) {
                    bounds.extend({lat: list.markers[i].getPosition().lat(), lng: list.markers[i].getPosition().lng()})
                }
                list.map.fitBounds(bounds);
            }
            else if ( list.params.coordinates ) {
                let coordinates = list.params.coordinates.split(',');
                const bounds = new google.maps.LatLngBounds();
                bounds.extend({ lat: +coordinates[0], lng: +coordinates[2] });
                bounds.extend({ lat: +coordinates[1], lng: +coordinates[3] });
                list.map.fitBounds(bounds);
            }
            return;
        }
        list.lastEvent = null;
    },
    paginate(page) {
        if ( page != list.params._page ) {
            list.params._page = page;
        }
        if ( list.$body.length ) {
            functions.setUrlData(list.params, list.$body.length);
        } else {
            list.paginated = true;
            list.$form.submit();
        }
    },
    find() {
        // init list
        if ( !list.$list ) {
            list.$list = $('.list-items').length ? $('.list-items') : $('.list');
            list.list = list.$list[0];
            list.$listWrap = list.$list.closest('.list-wrap');
            list.unsetLoadingMode();
        }
        list.setLoadingMode();
        let ajaxId = ++list.loadId;
        list.mapFind(ajaxId);

        let sendParams = this.cleanData();
        // if (sendParams.destination) sendParams.coordinates = null;
        $.get('/services/list', sendParams).then(response => {
            if ( ajaxId !== list.loadId ) {
                return;
            }
            response = JSON.parse(response);

            list.total = response.total;
            list.$list[0].innerHTML = response.html + pagination({
                currentPage: list.params._page,
                total: list.total,
                perPage: list.params.perPage
            });

            // functions.initCompare();
            // list.setResultsText();
            list.allowInfoWindow = true;
            list.domInits();
            list.unsetLoadingMode();
        });
    },
    setResultsText() {
        let maxResults = list.params._page * 10;
        list.$shownItems.html(
            (maxResults - 9) +
            ' - ' +
            (maxResults > list.total ? list.total : maxResults)
        );
        list.$total.html(list.total);
    },
    domInits() {
        
        const observerVillas = lozad('.card', {
            rootMargin: '100px 100px',
            loaded: el => {
                let listSlider = [];
                $(el).find('[id^=list-slider]').each((i, elem) => {
                    const length = $(elem).find('.keen-slider__slide').length;
                    listSlider[i] = new Slider('#' + $(elem).attr('id'), {
                        rubberband: false,
                        loop: true,
                        arrows: true,
                        // slidesPerView: 2,
                        spacing: 0,
                        pager: false,
                        // autoLoadSlides: 1
                    });
                });
                $(el).triggerHandler('lozadLoaded');
            }
        });
        observerVillas.observe();
        

        let $dropdown = $(".dropdown-sort-by");
        $dropdown.find('.dropdown-item').on('click', function (e) {
            e.preventDefault();
            list.params._page = 1;
            list.params.sortBy = $(e.currentTarget).attr('href');
            if ( list.$body.length ) {
                functions.setUrlData(list.params, true);
            } else {
                list.paginated = true;
                list.$form.submit();
            }
        });

        if (list.params.sortBy) {
            let text = $dropdown.find(".dropdown-item[href='"+list.params.sortBy+"']").html();
            $dropdown.find(".btn .sort-text").html(text);
        }

        observer.observe();
    },
    clearMarkers() 
    {
        //Loop through all the markers and remove
        for (var i = 0; i < list.markers.length; i++) {
            list.markers[i].setMap(null);
        }
        list.markers = null;
    },
    closeModal()
    {
        var modal = Modal.getInstance($('#filtersModal'));
        modal.hide();
        $('.modal-backdrop').remove();
    },
    cleanData()
    {
        list.splitDates(list.params);
        let sendParams = $.extend({}, list.params);
        if (sendParams.guests == '0,0' || sendParams.guests == '0,0,0') delete sendParams.guests
        return sendParams
    }
    
};

$(() => {
    list.init();
    list.loadMap();
})