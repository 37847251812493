import functions from './functions.js';
import Slider from './Slider.js';
import { SimpleGuests, SimpleGuestsDefaults } from "./simple-guests.js";
// import { FormGuide, FormGuideDefaults } from "./form-guide.js";
// import lozad from 'lozad';
// import KeenSlider from 'keen-slider';




$(function () {

    const language = functions.getLang();


    $('body').on('click', '.dropdown-menu', e => {
        e.stopPropagation();
    });

    const guestsTranslations = {
        en: {
            guests: 'guests',
            infants: 'infants'
        },
        de: {
            guests: 'gäste',
            infants: 'kleinkinder'
        },
        it: {
            guests: 'ospiti',
            infants: 'neonati'
        },
        hr: {
            guests: 'gosta',
            infants: 'dojenčad'
        }
    };

    $('.simple-guests').each((i, elem) => {
        elem._simpleGuests = new SimpleGuests(elem, {
            btnValueTpl: (adults, children, infants, extras) => {
                return `${adults + children + infants} ${guestsTranslations[language].guests}${extras ? ', ' + extras + ' ' + guestsTranslations[language].infants : ''}`;
            }
        });
    });

    let formGuide = [];
    $('.form-guide').each((i, elem) => {
        formGuide[i+1] = new FormGuide(elem);
    });
    
    $('.form-field').on('click', e => {
        if ($(e.target).hasClass('simple-guests-apply')) return
        e.stopImmediatePropagation();
        $(e.currentTarget).find('select').select2('open');
        if ($(e.currentTarget).find('.datepicker-range').length) $(e.currentTarget).find('.datepicker-range')[0]._flatpickr.open();
        if ($(e.currentTarget).find('.simple-guests').length) $(e.currentTarget).find('.simple-guests').dropdown('show');
        if ($(e.currentTarget).find('.form-guide').length) 
        {   
            let id = $( $(e.currentTarget).find('.form-guide')[0] ).data('fgid');
            formGuide[id].show();
        }
    });

    if(! $('.page-type-details')[0]) {
        $(window).scroll(e => {
            $(window).scrollTop() > 0 
            ? $('#navbarTogglerMain').addClass('visible')
            : $('#navbarTogglerMain').removeClass('visible');
        });
    }

    let homeSlider = [];
    $('[id^=home-slider]').each((i, elem) => {
        const $slides = $(elem).find('.keen-slider__slide');
        homeSlider[i] = new Slider('#' + $(elem).attr('id'), {
            loop: true,
            fade: true,
            arrows: true,
            slides: {
                perView: 1,
            },
            autoplay: 6000,
            defaultAnimation: {
                duration: 0
            },
            detailsChanged: (s) => {
              s.slides.forEach((element, idx) => {
                if ( s.track.details.slides[idx].portion == 0 ) {
                    element.style.opacity = 0;
                }
                if ( s.track.details.slides[idx].portion == 1 ) {
                    element.style.opacity = 1;
                    element.classList.add('animation');
                }
              })
            },
            renderMode: "custom",
        });
    });
    

    let detailsSlider = [];
    $('[id^=details-banner-slider]').each((i, elem) => {
        const length = $(elem).find('.keen-slider__slide').length;
        detailsSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: true,
            arrows: true,
            slides: {
                perView: 1,
                spacing: 4
            },
            duration: 1000,
            autoplay: 0,
            pauseOnHover: true,
            pager: true,
            breakpoints: {
                '(max-width: 1000px)': {
                    slides: {
                        perView: 1
                    },
                },
            },
            autoLoadSlides: 3,
        });
    });
    


    let unitCards = [];
    $('[id^=unit-cards]').each((i, elem) => {
        unitCards[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: true,
            arrows: true,
            pager: false,
            slides: {
                perView: 1,
                spacing: 0,
            },
            autoplay: 0,
            drag: true,
            pauseOnHover: false,
            autoLoadSlides: 1
        });
    });


    

    // special offer slider
    $('[id^=card-slider]').each((i, elem) => {
        let $items = $(elem).find('.keen-slider__slide.card-slide');
       // console.log('asdasdadas', $items)
        new Slider('#' + $(elem).attr('id'), {
            //items: $items,
            selector: '.keen-slider__slide.card-slide',
            loop: true,
            drag: $items.length > 3 ? true : false,
            arrows: true,
            pager: true,
            slides: {
                perView: 3,
                spacing: 0
            },
            breakpoints: {
                '(max-width: 991px)': {
                    origin: 'center',
                    drag: true,
                    loop: true,
                    slides: {
                        perView: 2,
                        spacing: 0,
                    },
                },
                '(max-width: 767px)': {
                    origin: 'center',
                    drag: true,
                    loop: true,
                    slides: {
                        perView: 1,
                        spacing: 0,
                    },
                },
            },
        });
    });

    
    // let listSlider = [];
    // $('[id^=list-slider]').each((i, elem) => {
    //     const $items = $(elem).find('.keen-slider__slide');
    //     listSlider[i] = new Slider('#' + $(elem).attr('id'), {
    //         rubberband: false,
    //         //items: $items,
    //         drag: true,
    //         loop: true,
    //         arrows: true,
    //         spacing: 0,
    //         pager: false,
    //         slides: {
    //             perView: 1,
    //             spacing: 0
    //         },
    //     });
    // });
    

    // home reviews slider MOBILE
    $('[id=home-reviews-mobile-slider]').each((i, elem) => {
        let $items = $(elem).find('.keen-slider__slide');
        new Slider(elem, {
            loop: true,
            arrows: false,
            pager: true,
            slides: {
                perView: 1.10,
                spacing: 15,
            },
        });
    });


    // home reviews slider
    $('[id=home-reviews-slider]').each((i, elem) => {
        let $items = $(elem).find('.keen-slider__slide');
        new Slider(elem, {
            loop: true,
            arrows: true,
            pager: false,
            fade: true,
            slides: {
                perView: 1,
            },
            breakpoints: {
                '(max-width: 992px)': {
                    fade: false,
                    renderMode: 'precision'
                },
            },
            // autoplay: 6000,
            // defaultAnimation: {
            //     duration: 0
            // },
            detailsChanged: (s) => {
                if (!s.options.fade) { return; }
                s.slides.forEach((element, idx) => {
                    if (s.track.details.slides[idx].portion == 0) {
                        element.style.opacity = 0;
                    }
                    if (s.track.details.slides[idx].portion == 1) {
                        element.style.opacity = 1;
                        element.classList.add('animation');
                    }
                })
            },
            renderMode: "custom",
        });

        // $items.each((i, slide) => {
        //     slide.addEventListener('animationend', () => {
        //         slide.classList.remove('animation');
        //     });
        // });

    });


    let catalogueSliderCrads = [];
    $('[id^=catalogue-slider-cards]').each((i, elem) => {
        catalogueSliderCrads[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: true,
            arrows: true,
            pager: true,
            slides: {
                perView: 1,
                spacing: 0,
            },
            autoplay: 0,
            drag: true,
            pauseOnHover: false,
            autoLoadSlides: 1
        });
    });
   
    let blogSlider = [];
    $('[id^=blog-slider]').each((i, elem) => {
        const length = $(elem).children('.keen-slider__slide').length;
        blogSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: true,
            arrows: true,
            pager: true,
            slides: {
                perView: 3,
                spacing: 0,
            },
            autoplay: 0,
            drag: true,
            pauseOnHover: false,
            breakpoints: {
                '(max-width: 1050px)': {
                    origin: 'center',
                    slides: {
                        perView: 2.15,
                        spacing: 0,
                    },
                    loop: false,
                    drag: true,
                    arrows: false,
                    autoplay: 0
                },
                '(max-width: 768px)': {

                    origin: 'center',
                    slides: {
                        perView: 1.08,
                        spacing: 0,
                    },
                    loop: false,
                    drag: true,
                    autoplay: 0
                },
            },
            //selector: '.first-slide',  
        });
    });

    let categoriesSlider = [];
    $('[id^=categories-slider]').each((i, elem) => {
        const length = $(elem).children('.keen-slider__slide').length;
        categoriesSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: true,
            loop: true,
            arrows: true,
            drag: true,
            slides: {
                perView: 4,
                spacing: 0,
            },
            autoplay: 0,
            breakpoints: {
                '(max-width: 1050px)': {
                    origin: 'center',
                    slides: {
                        perView: 2.15,
                        spacing: 0,
                    },
                    loop: false,
                    drag: true,
                    arrows: false,
                    autoplay: 0
                },
                '(max-width: 768px)': {
                    origin: 'center',
                    slides: {
                        perView: 1.07,
                        spacing: 0,
                    },
                    loop: false,
                    drag: true,
                    arrows: false
                },
            },
        });
    });



    /* gallery */
    const lg = {};
    window.showGallery = (index, id) => {
        index = +index || 0;
        if (lg[id]) {
            lg[id].index = index;
            lg[id].build(index);
        } else {
            let $elem = $('[data-images-' + id + ']');
            lg[id] = $elem.lightGallery({
                dynamic: true,
                thumbnail: true,
                toogleThumb: false,
                thumbWidth: 130,
                thumbMargin: 10,
                download: false,
                preload: 2,
                index: index,
                dynamicEl: JSON.parse($elem.attr('data-images-' + id))
            }).data('lightGallery');
        }
    };
    $('[data-gallery-id]').on('click', function (e) {
        e.preventDefault();
        let galleryId = $(this).attr('data-gallery-id');
        galleryId && showGallery($(this).attr('data-index'), galleryId);
    });
    // don't focus div with gallery
    $('[data-gallery-id]').on('focus', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        return false;
    });

    const $wpGallery = $('.wp-block-gallery');
    const $wpGalleryLinks = $wpGallery.find('a');
    const wpGalleryArray = $wpGalleryLinks.map((i, elem) => {
        const $elem = $(elem);
        const $img = $elem.find('img');
        // let imgs = $img.attr('src').split(', ');
        let imgs = $img.attr('srcset').split(', ');
        let sizes = $.map(imgs, img => {
            let size = img.match(/(\d+)w$/);
            return size ? +size[1] : 9999;
        });
        let minSize = sizes.indexOf(Math.min.apply(Math, sizes));
        minSize = minSize > -1 ? minSize : 0;
        let img = imgs[minSize].replace(/ \d+w$/, '');
        return {
            src: $elem.attr('href'),
            thumb: img
        };
    });
    $wpGallery.on('click', 'a', e => {
        e.preventDefault();
        let index = $wpGalleryLinks.index(e.currentTarget);
        if ($(e).hasClass('lg-on')) {
            $(e).data('lightGallery').index = index;
            $(e).data('lightGallery').build(index);
        } else {
            $(e).lightGallery({
                dynamic: true,
                thumbnail: true,
                toogleThumb: false,
                thumbWidth: 130,
                thumbMargin: 10,
                download: false,
                autoplay: false,
                preload: 2,
                index: index,
                dynamicEl: wpGalleryArray
            }).addClass('lg-on');
        }
    });

    const viewAllTranslation = {
        en: 'Images',
        de: 'Bilder',
        it: 'Immagini',
        hr: 'Slika'
    };

    $wpGallery.each((i, elem) => {
        const $links = $(elem).find('a');
        let eq = $links.length >= 4 ? 3 : $links.length - 1;
        $links.eq(eq).append(
            `<div class="see-all-images">
            <div class="btn btn-light btn-sm pointer border border-warning rounded-0" data-gallery-id="gallery-section" data-index="0">
                <i class="las la-camera-retro text-warning la-lg"></i>
                <span class="text-dark">${wpGalleryArray.length} ${viewAllTranslation[functions.getLang()]}</span>
            </div>
        </div>`
        );
    });

    /* end gallery */

    // menu links active
    const path = location.pathname.replace(/\/$/, '');
    $('.main-header [href], footer [href]').each((i, link) => {
        let href = link.getAttribute('href');
        if (href && href != '#') {
            href = href.split('?')[0].replace(/\/$/, '');
            if (path === href) {
                $(link).addClass('active').closest('.nav-item').addClass('active');
            }
        }
    });

    // global tooltip init
    //('[data-toggle="tooltip"]').tooltip();

    // mobile menu handling
    (() => {
        const $header = $('.main-header');
        const $collapser = $('#main-menu');

        // ensure navbar toggler also open closes main-menu
        $collapser
            .on('show.bs.collapse', () => {
                document.body.classList.add('modal-transition');
                document.body.classList.add('modal-active');
            })
            .on('hide.bs.collapse', () => {
                document.body.classList.remove('modal-active');
            })
            .on('hidden.bs.collapse', () => {
                document.body.classList.remove('modal-transition');
            });

        // click on "overlay" closes menu
        $header.find('.header-overlay').on('click', () => {
            $collapser.collapse('hide');
        });
    })();

    // scrool to element handling
    $(document).on('click', '[data-scroll-to]', e => {
        e.preventDefault();
        const $this = $(e.target);
        let scrollTo = $this.attr('data-scroll-to').split(',');
        functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
    });

    // global datepicker init with automatic "dateTo" set if provided
    let displayDate = new Date('2019-06-01');
    let now = functions.cleanDate(new Date);
    if (now > displayDate) {
        displayDate = now;
    }

    $('.datepicker').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        $datepicker.flatpickr({
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            minDate: minDate ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-angle-left pr-2"></i>',
            nextArrow: '<i class="la la-angle-right pl-2"></i>',
            onChange(selectedDates, dateStr, instance) {

                const $first = $(instance.input);
                // prevent other logic if second datepicker is triggered by first
                if (instance._openByFirst) {
                    delete instance._openByFirst;
                    return;
                }

                if ($first.attr('data-firstDate')) {
                    const $second = $first.closest('form').find('[data-secondDate]').closest('.datepicker');
                    if ($second.length) {

                        var firstDate = new Date(selectedDates[0]);
                        firstDate.setDate(firstDate.getDate() + 1);

                        var secondInstance = $second[0]._flatpickr;
                        // check if firstDate is invalid
                        if (firstDate.getTime() === firstDate.getTime()) {
                            secondInstance._openByFirst = true;
                            secondInstance.set('minDate', firstDate);
                            if (!secondInstance.selectedDates[0]) {
                                firstDate.setDate(firstDate.getDate() + 6);
                                secondInstance.setDate(firstDate);
                            }
                            secondInstance.open();
                            $(secondInstance.altInput).focus();
                        }
                        else if (secondInstance.selectedDates[0]) {
                            secondInstance.clear();
                        }
                    }
                }
                // check if second date is cleared
                else if ($first.attr('data-secondDate') && !selectedDates[0]) {
                    $first.closest('form').find('[data-firstDate]').closest('.datepicker')[0]._flatpickr.clear();
                }
            },
            onOpen(selectedDates, dateStr, instance) {
                if (instance.input.getAttribute('data-secondDate')) {
                    const $first = $(instance.input).closest('form').find('[data-firstDate]').closest('.datepicker');
                    if ($first.length) {
                        const firstInstance = $first[0]._flatpickr;
                        if (!firstInstance.input.value) {
                            instance.close();
                            firstInstance.open();
                            firstInstance.altInput.focus();
                            return;
                        }
                    }
                }
                !dateStr && instance.jumpToDate(minDate ? minDate : displayDate);
            }
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    });

    $('.datepicker-range').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
        const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
        const duration = Number($datepicker.attr('data-duration'));
        const position = $datepicker.attr('data-position') || 'auto';
        const minStayText = $datepicker.attr('data-minstay');
        $datepicker.flatpickr({
            mode: "range",
            showMonths: functions.isMobile() ? 1 : 2,
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            defaultDate: defaultDate,
            minDate: minDate && minDate >= now ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-arrow-left la-fw la-2x pr-2"></i>',
            nextArrow: '<i class="la la-arrow-right la-fw la-2x pl-2"></i>',
            locale: {
                rangeSeparator: ' - '
            },
            position: position,
            minStayText: minStayText ? minStayText : '',
            onDayCreate(dObj, dStr, fp, dayElem) {

                let minStay = 1;
                let to = new Date(maxDate);
                let weekday = new Date(dayElem.dateObj).getDay();
                weekday = '' + (weekday ? weekday : 7);
                to.setHours(0, 0, 0, 0);
                let onlyCheckInSelected = fp.selectedDates[0] && !fp.selectedDates[1];
                // let diff = globals.dateDiff(dayElem.dateObj, fp.selectedDates[0]);
                let from = dayElem.dateObj;
                let too = fp.selectedDates[0];
                // let f = Date.UTC(from.getFullYear(), from.getMonth()+1, from.getDate());
                // let t = Date.UTC(too.getFullYear(), too.getMonth()+1, too.getDate());

                let diff = Math.round((from - too) / (1000 * 60 * 60 * 24));

                if (onlyCheckInSelected) {
                    if (Math.abs(diff) < minStay) {
                        dayElem.className += ' flatpickr-disabled ';
                        if (diff) {
                            if (minStayText) {
                                $(dayElem).tooltip({
                                    title: minStayText.replace('{$min_stay}', minStay) || ''
                                });
                            }
                        }
                    }
                }

                if (dayElem.dateObj > to || (arrival.length && $.inArray(weekday, arrival) == -1)) {
                    dayElem.classList.add('unselectable');
                }
            },
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    });
    


    // select2 templates can be defined as data-template="destinations" ...
    const selectTemplates = {
        destinations: data => {
            if (!data.element) {
                return data.text;
            }
            if (data.placeId && data.placeName) {
                //const $element = $(data.element).addClass(data.element.className).text(data.placeName);
                const $element = $('<span>').addClass(data.element.className).text(data.placeName);
                return $element;
            }
            if (data.text) {
                const $element = $('<span>').addClass(data.element.className).text(data.text);
                let elemClass;
                switch ((data.id).split(':').length) {
                    case 1: elemClass = 'ps-1 fw-bold'; break;
                    case 2: elemClass = 'ps-3 fw-bold'; break;
                    case 3: elemClass = 'ps-5 '; break;
                    case 4: elemClass = 'ps-5 '; break;
                    default: elemClass = 'h5'; break;
                }
                $element.addClass(elemClass);
                return $element;
            }
        },
    };

    // global init select2
    // data-templates
    // data-data
    // data-allow-clear
    // data-nosearch
    // placeholder
    $.map($('.select2'), elem => {
        const $elem = $(elem);
        const config = { dropdownParent: $elem.parent(), language: language };
        config.allowClear = $elem.attr('data-allow-clear');
        // find select2 which has an attribute data-data
        const data = $elem.attr('data-data');
        if (data) {
            try {
                config.data = JSON.parse(data);
            } catch (e) { }
        }
        else if ($elem.attr('data-special')) {
            let newData = $('body').attr('data-select-' + $elem.attr('data-special'));
            config.data = JSON.parse(newData);
        }

        if ($elem.attr('data-template')) {
            config.templateResult = selectTemplates[$elem.attr('data-template')];
        }
        if ($elem.attr('data-template-selection')) {
            config.templateSelection = selectTemplates[$elem.attr('data-template-selection')];
        }

        if ($elem.attr('data-nosearch') !== undefined) {
            config.minimumResultsForSearch = Infinity;
        }

        let placeholder = $elem.attr('placeholder');
        $elem.removeAttr('placeholder');
        config.placeholder = placeholder || ' ';

        $elem.select2(config)
            .on('select2:clear', () => {
                $elem[0].cleared = true;
            })
            .on('select2:opening', e => {
                if ($elem[0].cleared) {
                    e.preventDefault();
                    $elem[0].cleared = false;
                }
            });
    });

    // advanced form controls
    // input-popover
    $('body').on('click', '.dropdown-menu-popover', e => {
        e.stopPropagation();
    });

    $('#cookie-bar-accept').on('click', function (e) {
        functions.setCookie('cookie_usage', 'accepted');
        $("#cookie-bar").addClass('d-none');
    });


    $('.nav-collapse').on('click', function (e) {
        let $target = $(this);
        $target.parent().toggleClass('collapse-active', !$target.hasClass('collapsed'));
    })



    $('.show-more').each( (i, elem) => {
        let $showMore = $(elem);

        let height = $showMore.attr('data-height');
        if ($showMore.find('.show-more-content').outerHeight() < height)
        {
            $showMore.addClass('removeBefore');
            $showMore.next('.show-more-expand').remove();
            return;
        }
        
        let $breakpoint = $showMore.find('.show-more-breakpoint');
        if (height)
        {
            $showMore.css('max-height', +height);
        }
        else if ($breakpoint.length) 
        {
            $showMore.css('max-height', $breakpoint.offset().top - $showMore.offset().top - 25);
        }
        else 
        {
            let $maxHeight = functions.isMobile() ? 
                        ($showMore.outerHeight() * 0.15) : 
                        ($showMore.outerHeight() * 0.3);

            $showMore.css('max-height', $maxHeight);
        }
    
        $showMore.next('.show-more-expand').on('click', e => {
            let $prevSibling = $(e.currentTarget).prev();
            let $maxHeight
            if ($prevSibling.hasClass('removeBefore'))
            {
                let height = $prevSibling.attr('data-height');
                let $breakpoint = $prevSibling.find('.show-more-breakpoint');
                if (height)
                {
                    $maxHeight = +height;
                }
                else if ($breakpoint.length) 
                {
                    $maxHeight = $breakpoint.offset().top - $showMore.offset().top - 25;
                }
                else 
                {
                    $maxHeight = functions.isMobile() ? 
                        ($prevSibling.outerHeight() * 0.15) : 
                        ($prevSibling.outerHeight() * 0.3);
                }
                $prevSibling.css('max-height', $maxHeight);
                $prevSibling.removeClass('removeBefore');
                if ($prevSibling.hasClass('show-more-scroll')) 
                {
                    $('html, body').stop().animate({
                        scrollTop: $prevSibling.offset().top + $maxHeight - $(e.target).outerHeight(),
                    }, 0, 'linear');
                }
            }
            else 
            {
                let $content = $prevSibling.find('.show-more-content');
                $prevSibling.css('max-height', $content.outerHeight());
                $prevSibling.addClass('removeBefore');
            }
        })
    });

    let listSlider = [];
    $('[id^=details-unit-card-slider]').each((i, elem) =>
    {
        const length = $(elem).find('.keen-slider__slide').length;
        listSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: true,
            arrows: true,
            // slidesPerView: 2,
            spacing: 0,
            pager: false,
            // autoLoadSlides: 1
        });
    });

    // let cookieConsent = functions.getCookie('cookie_consent');
    // if (! cookieConsent)
    // {
    //     if ($('.map-and-filters').length) $('.map-and-filters').addClass('pb-5');
    //     if ($('.list').length) $('.list').addClass('pb-5');
    //     let $cookieBar = $('#cookie-bar');
    //     $cookieBar.removeClass('d-none');

    //     $cookieBar.find('.cookie-agree').on('click', (e) => {
    //         $cookieBar.addClass('d-none');
    //         functions.setCookie('cookie_consent', (Math.random() * 10000).toString(36));
    //         if ($('.map-and-filters').length) $('.map-and-filters').removeClass('pb-5');
    //         if ($('.list').length) $('.list').removeClass('pb-5');
           
    //     })
    // }
});


