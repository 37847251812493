import './scss/style.scss';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import flatpickr from "flatpickr";
flatpickr.localize(flatpickr.l10ns[functions.getLang()]);
flatpickr.l10ns.default.firstDayOfWeek = 1;
import 'flatpickr/dist/l10n/hr.js';
import 'flatpickr/dist/l10n/it.js';
import 'flatpickr/dist/l10n/de.js';


import 'lightgallery/src/js/lightgallery.js';
import 'lightgallery/modules/lg-fullscreen.js';
import 'lightgallery/modules/lg-thumbnail.js';

import 'select2';
import 'select2/dist/js/i18n/hr.js';
import 'select2/dist/js/i18n/de.js';
import 'select2/dist/js/i18n/it.js';

import lozad from 'lozad';

import functions from './js/functions.js';
import './js/loadGmap.js';
import './js/globals.js';
import './js/Slider';
import './js/list.js';
import './js/details.js';
import './js/booking.js';
import './js/contact.js';
import './js/newsletter.js';
// import './js/home.js';
// import './js/landing.js';
// import './js/blog.js';
import './js/review.js';
//import './js/pagination.js';

// import './js/gutenberg/gutenberg.js';

window.observer = lozad('.lozad', {
    rootMargin: '200px 200px',
    loaded: el => {
        $(el).triggerHandler('lozadLoaded');
    }
});
window.observer.observe();

window.dataSrc = lozad('img[data-src]', {
    rootMargin: '200px 200px',
    loaded: el => {
        $(el).triggerHandler('lozadLoaded');
    }
});
window.dataSrc.observe();


window.nobserver = lozad('.load-recaptcha', {
    rootMargin: '200px 0px',
    loaded: el => {
        $.getScript("https://www.google.com/recaptcha/api.js?render=" + functions.getRecaptchaKey());
    }
});
window.nobserver.observe();
