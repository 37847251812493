import functions from "./functions.js";

$(function () {

    const $form = $('.booking-form');
    const form = $form[0];

    if (!form) {
        return;
    }

    const booking = {
        $form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,
        promoDiscount: null,
        totalAmount: null,
        totalAmountConverted: null,
        paymentModelId: null,
        newsletter: {},

        init() {
            booking.$form = $(".booking-form");
            booking.promoCodeData = {};
            booking.$calculation = $(".table-calculation");
            booking.$payment = $(".payment-details");
            booking.params = {};

            let paymentModel = booking.$form.attr('data-defaultPaymentModel');
            booking.paymentModelId = +paymentModel;

            booking.$form.find('input[type="radio"].paymentModelId').change(function () {
                booking.paymentModelId = this.value;
            });

            booking.$calculation.find('input[type="checkbox"]').change(function (e) {
                let $row = $(this).closest('.calc-item-row');
                let $quantity = $row.find('select.quantity');
                let value = +$quantity.val();
                let checked = $(this).is(':checked') ? 1 : 0;
                if (checked && !value) {
                    $quantity.prop('value', 1);
                } else if (!checked && value) {
                    $quantity.prop('value', 0);
                }

                booking.calculate($(this));

                if (booking.promoCodeData.percent) {
                    booking.applyDiscount();
                }
            });

            let guests = functions.getUrlData().guests;
            if(guests && $('.simple-guests')[0]) {
                $('.simple-guests').each( (index, elem) => {
                    elem._simpleGuests.setValue(guests);
                });
            }

            $('.calc-item-row [name="quantity"]').change(function (e) {
                let $row = $(this).closest('.calc-item-row');
                let $check = $row.find('input[type="checkbox"]');
                let value = +$(this).val();
                let checked = $check.is(':checked') ? 1 : 0;

                if (!checked && value) {
                    $check.prop('checked', true);
                } else if (checked && !value) {
                    $check.prop('checked', false);
                }

                booking.calculate($(this));

                if (booking.promoCodeData.percent) {
                    booking.applyDiscount();
                }
            });

            $('.payment-type-wrapper').on('click', function (e) {
                let labelTag = $(e.target).closest('label').attr('for');
                let $labels = $(this).find('label');
                $labels.each((index, item) => {
                    let $elem = $(item);
                    if ($elem.attr('for') == labelTag)
                    {
                        $elem.removeClass('border-white').addClass('border-warning');
                    }
                    else
                    {
                        $elem.removeClass('border-warning').addClass('border-white');
                    }
                    let $transferInfo = $elem.find('.bank-transfer-info');
                    if($transferInfo.length) $transferInfo.toggleClass('active', $elem.attr('for') == labelTag)
                })
            });

            $(".btn-promo-code").on('click', e => {
                let params = {};
                params['language'] = functions.getLang();
                params['promoCode'] = $("input[name='promoCode']").val();

                $.post('/services/promoCode/', params).then(response => {
                    response = JSON.parse(response);
                    $(".promo-code-output").removeClass('d-none').html(response.data);
                    if (response.status == true) {
                        booking.promoCodeData = response;
                        booking.promoCodeData.promoCode = params['promoCode'];
                        
                        $(".btn-promo-code").attr('disabled', true);
                        booking.applyDiscount();
                    }
                });
            });

            booking.$calculation.find("*[data-calc]").each(function (index) {
                let item = JSON.parse($(this).attr('data-calc'));
                item.inCalc = item.isOptional ? 0 : 1;
                booking.calc.push(item);
            });

            // $('.fee-text').tooltip({
            //     title: $('.fee-text').attr('data-tooltiptext') || ''
            // });
            // $('.voucher-text').tooltip({
            //     title: $('.voucher-text').attr('data-tooltiptext') || ''
            // });



            booking.$calculation.find('select.quantity').change(function (e) {
                let $row = $(this).closest('tr');
                let $checkbox = $row.find('input[type="checkbox"]');
                let value = +$(this).val();

                if (value && !$checkbox.is(':checked')) {
                    $checkbox.prop('checked', true);
                } else if (!value && $checkbox.is(':checked')) {
                    $checkbox.prop('checked', false);
                }

                booking.calculate($(this));

            });

            booking.total();

            booking.$form.on('submit', e => {


                // booking.newsletter.newsletter = booking.$form.find('[name=newsletter]').is(':checked');
                // if(booking.newsletter.newsletter) {
                //     booking.newsletter.email = booking.$form.find('[name=email]').val();
                //     booking.newsletter.language = functions.getLang();
                //     $.post('/services/mailchimp/', booking.newsletter).then(response => {

                //     }).fail(error => {
                        
                //     });
                // }
                // if ( ! booking.$payment.find("[name='paymentType']")[0].checkValidity()) {
                //     e.preventDefault();
                //     booking.$payment.find("[name='paymentType']")[0].reportValidity()
                // }
                // e.preventDefault();
                booking.submit();

            });

        },
        submit() {
            // debugger;
            $.each(booking.calc, function(key, item) {
                if ( ! item.inCalc) {
                    delete booking.calc[key];
                } else if (item.itemDefinitionId.categoryId.key == 'baseRate') {
                    let $guestsPerProduct = $("[data-guests-parentId='" + item.parentId + "']");
                    let guests = [];

                    if ($guestsPerProduct.length) {
                        $guestsPerProduct.find('[data-guests]').each(function (index) {
                            let g = +$(this).data('guests');
                            guests[index] = {
                                firstName : $(this).find("input[name='firstName" + g + "']").val(),
                                lastName : $(this).find("input[name='lastName" + g + "']").val(),
                                age : $(this).find("input[name='age" + g + "']").val()
                            };
                        });
                        booking.calc[key].guestsData = guests;
                    }
                }
            });


            booking.params.paymentType = 'bankTransfer';
            booking.params.calc = JSON.stringify(booking.calc);
            booking.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);
            $.each(booking.params, function (key, param) {
                $('<input>').attr({
                    type: 'hidden',
                    name: key,
                    value: param
                }).appendTo(booking.$form);
            });

        },
        calculate($elem, promo = false) {
            let $row = $elem.closest('.calc-item-row');
            // debugger;
            let $checkbox = $row.find('input[type="checkbox"]');
            let checked = $checkbox.is(':checked') ? 1 : 0;
            let id = $checkbox.attr('id');
            
            let $quantity = $row.find('select.quantity:visible');
            let quantity = null;
            
            if ($quantity.length) {
                quantity = +$quantity.val();
            }
            
            $.each(booking.calc, function (key, calc) {
                if (calc.id == id) {
                    
                    if (quantity == null) {
                        quantity = booking.calc[key].quantity;
                    }
                    
                    booking.calc[key].inCalc = checked;
                    booking.calc[key].quantity = (quantity || 1);
                    booking.calc[key].total = booking.itemTotal(booking.calc[key]);
                    booking.calc[key].totalConverted = booking.itemTotalConverted(booking.calc[key]);

                    $row.find('.price').html(functions.formatMoney(booking.calc[key].total, 2, ',', '.'));
                    $row.find('.price-converted').html(functions.formatMoney(booking.calc[key].totalConverted, 2, ',', '.'));
                }
            });


            booking.total(promo);
        },
        itemTotal(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.price;
                } else {
                    itemTotal = +item.price;
                }
            } else {
                itemTotal = +item.quantity * +item.price;
            }
            return itemTotal;
        },
        itemTotalConverted(item) {
            let itemTotal = 0.00;
            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.priceConverted;
                } else {
                    itemTotal = +item.priceConverted;
                }
            } else {
                itemTotal = +item.quantity * +item.priceConverted;
            }

            return itemTotal;
        },
        total(promo = false) {
            booking.totalAmount = 0.00;
            booking.totalAmountConverted = 0.00;

            $.each(booking.calc, function (key, calc) {
                if (!calc.onSpot && calc.inCalc) {
                    booking.totalAmount += booking.itemTotal(calc);
                    booking.totalAmountConverted += booking.itemTotalConverted(calc);
                }
            });

            // let fee = booking.totalAmount * 2.5/100;

            // if (promo) fee = fee/promo;

            // const feeConverted = fee * 7.53450;

            console.log(booking.totalAmountConverted);

            $('.total-amount').html('€ ' + functions.formatMoney(booking.totalAmount, 2, ',', '.'));
            $('.total-amount-converted').html(functions.formatMoney(booking.totalAmountConverted, 2, ',', '.') + ' kn');

            const advancePaymentPercent = $('[data-advancePaymentPercent]').attr('data-advancepaymentpercent');

            // $('.fee-amount').html(functions.formatMoney(fee, 2, ',', '.'));
            // $('.fee-amount-covnerted').html('kn ' + functions.formatMoney(feeConverted, 2, ',', '.'));

            const advance = booking.totalAmount * (advancePaymentPercent / 100);
            const rest = booking.totalAmount - advance;
            $('.advance').html('€ ' + functions.formatMoney(advance, 2, ',', '.'));
            $('.rest').html('€ ' + functions.formatMoney(rest, 2, ',', '.'));
            
            const advanceConverted = booking.totalAmountConverted * (advancePaymentPercent / 100);
            const restConverted = booking.totalAmountConverted - advanceConverted;
            
            $('.advance-converted').html(functions.formatMoney(advanceConverted, 2, ',', '.')  + ' kn');
            $('.rest-converted').html(functions.formatMoney(restConverted, 2, ',', '.')  + ' kn');
        },
        applyDiscount() {
            booking.$calculation.each(function (index) {

                $(this).find(".promoDiscountItem").remove();
                let promoDiscountItem = JSON.parse($(this).find("*[data-calc]:first").attr('data-calc'));
                let promoDiscountItemId = promoDiscountItem.id + '_' + 114;

                let total = 0;
                let totalConverted = 0;
                $(this).find("*[data-calc]").each(function (index) {
                    let item = JSON.parse($(this).attr('data-calc'));
                    let quantity = item.isOptional ? $(this).find('select.quantity').val() : item.quantity;
                    if ( ! item.onSpot && quantity) {
                        total += +item.price * +quantity;
                        totalConverted += +item.priceConverted * +quantity;
                    }
                });
                total += total * 2.5/100;

                $.each(booking.calc, function (key, calc) {
                    if (calc && calc.id == promoDiscountItemId) {
                        booking.calc.splice(key, 1);
                    }
                });

                let discountAmount = (-1 * (total * (booking.promoCodeData.percent/100)));
                let discountAmountConverted = (-1 * (totalConverted * (booking.promoCodeData.percent/100)));

                promoDiscountItem.id = promoDiscountItemId;
                promoDiscountItem.itemDefinitionId.id = 114;
                promoDiscountItem.itemDefinitionId.text = 'Promo';
                promoDiscountItem.itemDefinitionId.categoryId.id = 80;
                promoDiscountItem.itemDefinitionId.categoryId.key = 'discount';
                promoDiscountItem.itemDefinitionId.objectGroupId.id = 1;
                promoDiscountItem.itemDefinitionId.objectGroupId.key = 'accommodation';
                promoDiscountItem.itemDefinitionId.typeId.id = 2;
                promoDiscountItem.itemDefinitionId.typeId.key = 'supplement';
                promoDiscountItem.priceIn = discountAmount;
                promoDiscountItem.price = discountAmount;
                promoDiscountItem.totalIn = discountAmount;
                promoDiscountItem.total = discountAmount;
                promoDiscountItem.priceConverted = discountAmountConverted;
                promoDiscountItem.noteHidden = booking.promoCodeData.promoCode;
                promoDiscountItem.skipCalculate = true;

                let hideConverted = functions.getLang() == 'hr' ? '' : 'd-none';

                $('.promo-item-wrap').remove();

                $('<div class="col-12 promo-item-wrap"> \n'+
                    '<div class="promoDiscountItem row align-items-start justify-content-lg-start justify-content-between calc-item-row" data-calc=\'' + JSON.stringify(promoDiscountItem) + '\'>\n' +
                    '        <div class="col-lg-auto col-auto pe-0">\n' +
                    '            <div class="custom-control custom-checkbox">\n' +
                    '                <input type="checkbox" id="' + promoDiscountItem.id + '" name="' + promoDiscountItem.id + '" checked="" disabled="">\n' +
                    '            </div>\n' +
                    '        </div>\n' +

                    '        <div class="col-lg-5 col">\n' +
                    '           <label for="'+ promoDiscountItem.id +'"\n' +
                    '               <span> ' +promoDiscountItem.itemDefinitionId.text + '</span>\n'+ 
                    '           </label>\n' +
                    '        </div>\n' +

                    '        <div class="col-lg col-auto text-end" style="line-height: 1.3;">\n' +
                                '€ \n' +
                    '           <span class="price">\n' +
                                    functions.formatMoney(discountAmount, 2, ',', '.') +
                    '           </span>\n' +
                    '           <br><span class="converted small ' + hideConverted + '" style="font-size: 0.675em;">\n' +
                    '               kn\n' +
                                    functions.formatMoney(discountAmount * 7.53450, 2, ',', '.') +
                    '           </span>\n' +
                    '        </div>\n' +

                    
                '   </div></div>').insertBefore($(this).find(".booking-fee-wrapper"));

                booking.calc.push(promoDiscountItem);
                booking.calculate($(this).find(".promoDiscountItem"), 1 - booking.promoCodeData.percent/100);
            });

        },
    }

    booking.init();
});