import functions from "./functions.js";

$(function () {

    const $form = $('.newsletter-form');
    const form = $form[0];

    const lang = functions.getLang();
    let output = '';

    if (!form) {
        return;
    }

    $form.on('submit', e => {
        e.preventDefault();

        $form.addClass('processing');
        
        let params = {};
        $.each($form.serializeArray(), function (i, field) {
            params[field.name] = field.value;
        });

        params['language'] = functions.getLang();

        grecaptcha.ready(function () {
            grecaptcha.execute(functions.getRecaptchaKey(), { action: 'contact' }).then(function (token) {
                params['gRecaptchaToken'] = token;
                params = functions.getDataForUrl(params);
                $.post('/services/mailchimp/', params).then(response => {
                    
                    $form.addClass('done');
                    $form.removeClass('processing');
                    $form.find(".done-message").html(response.data);

                    setTimeout(() => { $form.removeClass('done');  }, 4000);
                    form.reset();
                });
            });
        });
    });
});
