import tplLoader from "./tpl-loader.js";
import functions from './functions.js';
import calendar from './calendar.js';
import { SimpleGuests } from "./simple-guests.js";

$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(() => {
    details.init();
});

const details = {
    $form: null,
    form: null,
    params: {
        objectId: null,
        unitId: null,
        guests: '2,0',
        dates: null,
        dateFrom: null,
        dateTo: null,
        objectGroupId: null,
        calcErrorMessages: null,
    },
    inquiryRequired: ['dates', 'firstName', 'lastName', 'email', 'phone', 'guests'],
    focusOnBooking: false,
    calcTotal: 0,
    trackingTotal: 0,
    init() {

        if (!$('.page-type-details')[0]) {
            return;
        }
        let isMobile = functions.isMobile();

        // if (isMobile) {
        //     $('.nav-bottom').addClass('visible');

        //     $('.btn-select-date').on('click', function () {
        //         setTimeout(() => {
        //             $('.contact-select-mobile').addClass('d-none');
        //             $('.show-on-calendar').removeClass('d-none');
        //         }, 300);
        //     });

        //     $('.book-now-nav-bottom').on('click', function () {
        //         let wh = $(window).outerHeight();
        //         functions.scrollToElement($('.booking-scroll-hook'), wh);
        //     });


        //     $(window).scroll(e => {

        //         var elementTop = $('.details-guests').offset().top;
        //         var elementBottom = elementTop + $('.details-guests').outerHeight();

        //         var bookTop = $('.btn-request').offset().top;
        //         var bookBottom = bookTop + $('.btn-request').outerHeight();
        //         var offset = bookBottom - elementBottom + 5;

        //         var viewportTop = $(window).scrollTop();
        //         var viewportBottom = viewportTop + $(window).height();

        //         if (elementBottom > viewportTop - offset && elementTop < viewportBottom) {
        //             if (details.datesSelected()) $('.nav-bottom').removeClass('visible');
        //             $('.contact-select-mobile').addClass('d-none');
        //             $('.show-on-calendar').removeClass('d-none');

        //         }
        //         else {
        //             $('.nav-bottom').addClass('visible');
        //             $('.contact-select-mobile').removeClass('d-none');
        //             $('.show-on-calendar').addClass('d-none');
        //         }


        //     });

        // }
        $(window).scroll(e => {
            const offset = $('#booking').offset().top - $('#booking').outerHeight();

            $(window).scrollTop() > 200
                ? $('.nav-top').addClass('visible')
                : $('.nav-top').removeClass('visible');

            $(window).scrollTop() > 200 && ! $('#booking').isInViewport() 
                && ! ($(window).scrollTop() + $(window).height() == $(document).height())
                ? $('.nav-bottom').addClass('visible')
                : $('.nav-bottom').removeClass('visible');


        });

        $('.btn-booking-align-bottom').on('click', function (e) {
            let wh = $(window).outerHeight();
            if (functions.isMobile()) functions.scrollToElement($('.booking-scroll-hook'), wh);
        })

        details.getParams();

        if (isMobile && details.datesSelected()) {
            $('.select-dates-nav-bottom').addClass('d-none');
            $('.book-now-nav-bottom').removeClass('d-none');
        }

        // if(details.params.calendar) functions.scrollToElement($('#booking'), 0);

        // let params = functions.getUrlData(true);
        // details.params.guests = params.guests;

        details.params.objectGroupId = $('#booking').attr('data-objectgroupid');
        details.params.unitId = $('#booking').attr('data-unitid');
        details.params.objectId = $('#booking').attr('data-objectid') || null;

        //details.splitCalendarDates($('[name=dates]').val());

        details.calcErrorMessages = $('#booking').attr('data-calcErrorMessages') ? JSON.parse($('#booking').attr('data-calcErrorMessages')) : null;

        if ($('[data-calendarconfig]')[0]) {
            details.calendarInit();
        }

        if (details.params.dateFrom && details.params.dateTo) {
            if ($('[name="dates"]')[0]._flatpickr) {
                $('[name="dates"]')[0]._flatpickr.setDate([details.params.dateFrom, details.params.dateTo]);
            }
            if ($('.datepicker-range').length && $('.datepicker-range')[0]._flatpickr) {
                $('.datepicker-range')[0]._flatpickr.setDate([details.params.dateFrom, details.params.dateTo]);
            }

            details.callbackOnDatesSelected(details.params.dateFrom, details.params.dateTo);
        }

        if (details.params.guests && $('.simple-guests')[0]) {
            $('.simple-guests').each((index, elem) => {
                elem._simpleGuests.setValue(details.params.guests);
            });
        }

        details.getCalculation();


        $('[name="dates"]').on('change', e => {
            e.preventDefault();
            const $target = $(e.target);
            details.splitCalendarDates($target.val());
            if (details.params.dateFrom && details.params.dateTo) {
                details.focusOnBooking = true;
                $('.nav-bottom').removeClass('visible');
                $('.select-dates-nav-bottom').addClass('d-none');
                $('.book-now-nav-bottom').removeClass('d-none');
            }
            functions.setUrlData(details.params, true, true);
        });

        $('.btn-clear-dates').on('click', function (e) {
            $('[name="dates"]')[0]._flatpickr && $('[name="dates"]')[0]._flatpickr.setDate([]);

            $('.datepicker-range').length && $('.datepicker-range')[0]._flatpickr && $('.datepicker-range')[0]._flatpickr.setDate([]);
            details.params.dateTo = details.params.dateFrom = null;

            functions.setFormData($('.unit-search-form'), details.params)
            functions.setUrlData(details.params, true, true);
            $('.total-converted').addClass('d-none');
            $('.total-converted-default').removeClass('d-none');
            details.callbackOnDatesSelected(details.params.dateFrom, details.params.dateTo);

            $('.btn-booking').addClass('disabled d-none');

            $('.calc-msg').addClass('d-none');

            $('.label-from').removeClass('d-none');
            $('.label-total').addClass('d-none');

            $('.no-calculation').removeClass('d-none');
            $('.calculation-text').addClass('d-none');

            ['.price-old', '.price', '.price-old-converted', '.price-converted'].forEach((elem, i) => {
                let $elem = $(elem);
                if ($elem.length) {
                    $elem.each((i, elem2) => {
                        let $elem2 = $(elem2);
                        let curr = $elem2.attr('data-currency');
                        let price = $elem2.attr('data-price');
                        if (price && price !== '0,00' && price !== '0') {
                            let formatedPrice = curr + ' ' + price;
                            // if (i < 2) formatedPrice = price + ' ' + curr;
                            $elem2.html(formatedPrice);
                        }
                        else {
                            $elem2.html('');
                        }
                    })
                }
            })
        });

        $('[name="guests"]').on('change', e => {
            e.preventDefault();
            const $target = $(e.target);
            details.params.guests = $target.val();
            functions.setUrlData(details.params, true, true);
        });

        $(window).on('hashchange', e => {
            details.getParams();
            details.getCalculation();
        });

        details.loadReviews();
        details.inquiryInit();


    },
    loadReviews() {
        $('.reviews-more').on('click', function (e) {
            let $button = $(e.currentTarget);
            let perPage = 3;
            let page = +$button.data('page') + 1;
            let total = Math.round(+$button.data('total') / perPage);

            $.post('/services/reviews/', { language: functions.getLang(), perPage: perPage, page: page, objectId: $button.data('objectid') }).then(response => {
                if (response.status == true) {
                    $button.data('page', page);
                    if (total <= page) {
                        $button.addClass('d-none');
                    }
                    $(".reviews").append(response.html);
                }
            });
        });
    },
    calendarInit() {
        let config = JSON.parse($('[data-calendarconfig]').attr('data-calendarconfig'));

        let defaultDate = $('[data-calendarconfig][data-defaultdate]').attr('data-defaultdate');
        let minDate = $('[data-calendarconfig]').attr('data-mindate');
        /*if( ! details.params.dateFrom && defaultDate) {
            let dates = defaultDate.split(' - ');
            //details.params.dates = defaultDate;
            details.splitCalendarDates(defaultDate);
            defaultDate = dates[1] ? [dates[0], dates[1]] : (dates[0] ? dates[0] : []);
        }*/

        calendar.calendar.init(
            $('.datepicker-range-book'),
            {
                availabilityMask: config.availabilityMask,
                startDate: config.startDate,
                minStay: config.minStay,
                periods: config.periods,
                translations: config.translations,
                defaultDate: defaultDate ? defaultDate : [],
                minDate: minDate ? minDate : 'today',
                mode: 'range',
                inline: true,
                containerClass: 'details-booking-calendar mx-auto'
            },
            details.callbackOnDatesSelected
        );
    },
    inquiryInit() {
        let now = functions.cleanDate(new Date);
        let $datesI = null;
        $('.datepicker-range-inquiry').each((i, elem) => {
            const $datepicker = $(elem);
            const minDate = $datepicker.attr('data-min');
            const maxDate = $datepicker.attr('data-max');
            const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
            const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
            const duration = Number($datepicker.attr('data-duration'));
            const position = $datepicker.attr('data-position') || 'auto';
            const minStayText = $datepicker.attr('data-minstay');
            $datesI = $datepicker.flatpickr({
                mode: "range",
                showMonths: functions.isMobile() ? 1 : 2,
                altInput: true,
                wrap: true,
                altFormat: "j.m.Y",
                dateFormat: "Y-m-d",
                defaultDate: defaultDate,
                minDate: minDate && minDate >= now ? minDate : now,
                maxDate: maxDate ? maxDate : null,
                disableMobile: true,
                prevArrow: '<i class="la la-arrow-left la-fw la-2x pr-2"></i>',
                nextArrow: '<i class="la la-arrow-right la-fw la-2x pl-2"></i>',
                locale: {
                    rangeSeparator: ' - '
                },
                position: position,
                minStayText: minStayText ? minStayText : '',
                onDayCreate(dObj, dStr, fp, dayElem) {

                    let minStay = 1;
                    let to = new Date(maxDate);
                    let weekday = new Date(dayElem.dateObj).getDay();
                    weekday = '' + (weekday ? weekday : 7);
                    to.setHours(0, 0, 0, 0);
                    let onlyCheckInSelected = fp.selectedDates[0] && !fp.selectedDates[1];
                    // let diff = globals.dateDiff(dayElem.dateObj, fp.selectedDates[0]);
                    let from = dayElem.dateObj;
                    let too = fp.selectedDates[0];
                    // let f = Date.UTC(from.getFullYear(), from.getMonth()+1, from.getDate());
                    // let t = Date.UTC(too.getFullYear(), too.getMonth()+1, too.getDate());

                    let diff = Math.round((from - too) / (1000 * 60 * 60 * 24));

                    if (onlyCheckInSelected) {
                        if (Math.abs(diff) < minStay) {
                            dayElem.className += ' flatpickr-disabled ';
                            if (diff) {
                                if (minStayText) {
                                    $(dayElem).tooltip({
                                        title: minStayText.replace('{$min_stay}', minStay) || ''
                                    });
                                }
                            }
                        }
                    }

                    if (dayElem.dateObj > to || (arrival.length && $.inArray(weekday, arrival) == -1)) {
                        dayElem.classList.add('unselectable');
                    }
                },
            });
            // to style clear toggler
            $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
        });

        $('form#inquiry').on('submit', e => {
            e.preventDefault();


            $('.contact-btn').addClass('d-none');
            $('.contact-spinner').removeClass('d-none');
            $('.contact-spinner').addClass('d-flex');

            let params = {};
            let allParams = $(e.currentTarget).serializeArray();
            $.each(allParams, function (i, field) {
                params[field.name] = field.value;
            });

            params['dates'] = params.datesi || '';

            if (!params.dates) {
                $datesI.open();
                $('.contact-btn').removeClass('d-none');
                $('.contact-spinner').addClass('d-none');
                $('.contact-spinner').removeClass('d-flex');
                return;
            }

            functions.setFormData($('form#inquiry'), params);
            params['language'] = functions.getLang();

            params['requestId'] = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);
            grecaptcha.ready(function () {
                grecaptcha.execute(functions.getRecaptchaKey(), { action: 'contact' }).then(function (token) {
                    params['gRecaptchaToken'] = token;
                    params = functions.getDataForUrl(params);
                    $.post('/services/inquiry/', params).then(response => {
                        response.status ? $('.request-success').html(response.data).removeClass('d-none') 
                            : $('.request-error').html(response.data).removeClass('d-none');
                        $('.contact-btn').removeClass('d-none');
                        $('.contact-spinner').addClass('d-none');
                        $('.contact-spinner').removeClass('d-flex');
                        setTimeout(() => {
                            $(".request-error, .request-success").addClass('d-none');
                        }, 7000);

                        if (response.status == true) {
                            e.currentTarget.reset();
                        }
                    });
                });
            });
        });
    },
    callbackOnDatesSelected(from, to) {

        if (to && !to[1]) {
            $('.calendar-message').removeClass('d-none').text(details.getCalcErrorMessage({ type: !from ? 'selectDates' : 'selectDepartureDate' }));
            $('.btn-booking').addClass('disabled d-none');
        }
    },
    splitCalendarDates(dates) {
        if (dates || dates == '') {
            const aDates = dates.split(' - ');
            details.params.dateFrom = aDates[0] ? aDates[0] : null;
            details.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    getParams() {
        details.params = $.extend({}, details.params, functions.getUrlData(true));
        details.splitCalendarDates(details.params.dates);
    },
    isBookable(data) {
        return data.isAvailable && data.calc.total && data.calc.status == 'Active';
    },
    isRequest(data) {
        return !data.isAvailable && data.calc.status == 'Request';
    },
    getCalcErrorMessage(error) {
        if (error && details.calcErrorMessages && details.calcErrorMessages[error.type]) {
            return details.calcErrorMessages[error.type].replace('{$condition}', error.condition);
        }
        return details.calcErrorMessages ? details.calcErrorMessages.general : null;
    },
    datesSelected() {
        return details.params.dateFrom && details.params.dateTo;
    },
    getCalculation(unitId = null) {


        const dateFrom = details.params.dateFrom;
        const dateTo = details.params.dateTo;
        unitId = details.params.unitId;

        if (
            (details.params.objectId || unitId)
            && details.params.guests
            && dateFrom
            && dateTo
            && details.params.objectGroupId
        ) {

            $('.calc-loader').prepend(tplLoader).scrollTop(0);
            $('.calc-wrapper').css('opacity', 0);
            $('.calc-wrapper').css('display', 'none');

            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/calc/'
                + (unitId ? '?unitId=' + unitId : '?objectId=' + details.params.objectId)
                + '&guests=' + details.params.guests
                + '&dateFrom=' + details.params.dateFrom
                + '&dateTo=' + details.params.dateTo
                + '&objectGroupId=' + details.params.objectGroupId
                + '&language=' + functions.getLang()
                + '&addAllOptionals=' + 1
            )
                .done(response => {

                    if (response.status) {
                        const data = response.data || [];

                        Object.entries(data).forEach(unit => {
                            const unitId = unit[0];
                            const unitData = unit[1];
                            const $unit = $('.booking-unit-card[data-unitid=' + unitId + ']');

                            const $btnBooking = $unit.find('.btn-booking');
                            if (details.isBookable(unitData)) {
                                const unitCalc = unitData.calc;

                                const calcItems = unitCalc.items;
                                let priceOld = 0.00;
                                let priceOldEUR = 0.00;
                                let discountTitle = null;

                                calcItems.forEach(item => {
                                    // popust za djecu ne ulazi u prikaz discount cijene
                                    if ((item.itemType != 'discount' || item.itemDefinitionId == 129) && !item.optional && !item.onSpot) {
                                        priceOld += item.itemTotal;
                                        priceOldEUR += item.itemTotalEUR;
                                    }
                                    if (item.itemType == 'discount') {
                                        discountTitle = item.itemTitle;
                                        if (item.data && item.data.text) {
                                            discountTitle = item.data.text;
                                        }
                                    }
                                });

                                details.calcTotal = unitCalc.total;

                                const total = unitCalc.currencySymbol + ' ' + functions.formatMoney(unitCalc.total, 0, '.', ',');
                                const priceOldFormated = unitCalc.currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ',');

                                const totalEUR = functions.formatMoney(unitCalc.totalEUR, 0, '.', ',') + ' kn';
                                const priceOldEURFormated = functions.formatMoney(priceOldEUR, 0, '.', ',') + ' kn';



                                if ($btnBooking.attr('href')) {
                                    $btnBooking.attr('href',
                                        $btnBooking.attr('href').split('?')[0] +
                                        '?unitId=' + unitId +
                                        '&dateFrom=' + details.params.dateFrom +
                                        '&dateTo=' + details.params.dateTo +
                                        '&guests=' + details.params.guests +
                                        '&addAllOptionals=' + 1
                                    );
                                }
                                let guestsText = unitCalc.guests.split(',', 2);
                                guestsText = guestsText.reduce((sum, num) => sum + +num, 0);

                                $btnBooking.removeClass('disabled d-none');

                                $unit.find('.label-from').addClass('d-none');

                                $unit.find('.calc-msg').addClass('d-none');

                                $unit.find('.price').html(total);
                                console.log(priceOld && +priceOld < +unitCalc.total);
                                $unit.find('.price-old').html(priceOld && priceOld > unitCalc.total ? priceOldFormated : '');

                                $unit.find('.price-converted').html(totalEUR);
                                $unit.find('.price-old-converted').html(priceOld && priceOld > unitCalc.total ? priceOldEURFormated : '');

                                $unit.find('.label-total').removeClass('d-none');

                                $unit.find('.no-calculation').addClass('d-none');
                                $unit.find('.calculation').removeClass('d-none');
                                $unit.find('.booking-guests').html(guestsText);
                                $unit.find('.booking-nights').html(unitCalc.nights);
                                $unit.find('.booking-period').html(functions.formatDate(dateFrom, 'dd.mm.yyyy.') + '. - ' + functions.formatDate(dateTo, 'dd.mm.yyyy.')+'.');




                            } else {

                                $btnBooking.addClass('disabled d-none');

                                $unit.find('.calc-msg').removeClass('d-none').html(details.getCalcErrorMessage(unitData.calc.message));

                                $unit.find('.label-from').removeClass('d-none');
                                $unit.find('.label-total').addClass('d-none');

                                $unit.find('.no-calculation').removeClass('d-none');
                                $unit.find('.calculation').addClass('d-none');

                                ['.price-old', '.price', '.price-old-converted', '.price-converted'].forEach((elem, i) => {
                                    let $elem = $unit.find(elem);
                                    if ($elem.length) {
                                        $elem.each((i, elem2) => {
                                            let $elem2 = $(elem2);
                                            let curr = $elem2.attr('data-currency');
                                            let price = $elem2.attr('data-price');
                                            if (price && price !== '0,00' && price !== '0') {
                                                let formatedPrice = curr + ' ' + price;
                                                // if (i < 2) formatedPrice = price + ' ' + curr;
                                                $elem2.html(formatedPrice);
                                            }
                                            else {
                                                $elem2.html('');
                                            }
                                        })
                                    }
                                })
                            }

                            setTimeout(() => {
                                let wh = $(window).outerHeight();
                                $('.calc-loader').removeClass('loading').find('.list-loader').remove();
                                $('.calc-wrapper').css('opacity', 1);
                                $('.calc-wrapper').css('display', 'flex');
                                if (functions.isMobile() && details.focusOnBooking) functions.scrollToElement($('.booking-scroll-hook'), wh);
                            }, 450)


                        });
                    }

                }).fail(function (error) {
                    console.log(error);
                });
        }

        else {
            $('.btn-booking').addClass('disabled d-none');
            $('.no-calculation').removeClass('d-none');
            $('.calculation').addClass('d-none');
            $('.btn-select-dates').removeClass('d-none');

            $('.calc-message').addClass('d-none');
            $('.unit-price-container').removeClass('d-none');

            $('.total-old').toggleClass('d-none', !$('.total-old').attr('data-minPriceHasDiscount'));
            $('.total-old').text($('.total-old').attr('data-minPriceHasDiscount') ? $('.total-old').attr('data-minPriceOld') : '');

        }
    }
}